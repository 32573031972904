import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './index.css';
import App from './App';
import Select from './Select';
import Termos from './Termos';
import Privacidade from './Privacidade';
import Links from './Links';
import Guandu from './Guandu';
import DadosProvider from './context/Dados';
import Recuperacao from './Recuperacao';
import Lojas from './Home/Lojas';
import Anuncios from './Home/Anuncios';
import DetalhesProduto from './Home/DetalhesProduto';
import Blockchain from './Home/BlockChain';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <DadosProvider>
      <Router>
        <Routes>
          <Route path="/" exact element={<App />} />
          <Route path="/termos" exact element={<Termos />} />
          <Route path="/lojas" exact element={<Lojas />} />
          <Route path="/blockchain" exact element={<Blockchain />} />
          <Route path="/anuncios" exact element={<Anuncios />} />
          <Route path="/detalhes/:categoria/:id/:userforn" element={<DetalhesProduto />} />
          <Route path="/privacidade" exact element={<Privacidade />} />
          <Route path="/links" exact element={<Links />} />
          <Route path="/recuperacao" exact element={<Recuperacao />} />
          <Route path="/guandu" exact element={<Guandu />} />
          <Route path='*' element={<Select />} />
        </Routes>
      </Router>
    </DadosProvider>
  </React.StrictMode>
);
