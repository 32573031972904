import { FaInstagram } from "react-icons/fa";
import { HiOutlineMail } from "react-icons/hi";
import { MdOutlineLocalPhone } from "react-icons/md";
import { IoIosArrowBack } from "react-icons/io";
import logowhite from '../src/img/logowhite.png'

export default function Footer() {

    const verde = '#88d374'

    const contato = {
        fontSize: '20px', textAlign: 'center', color: '#fff', marginLeft: '10px'
    }

    const termos = {
        fontSize: '14px', textAlign: 'center', color: '#fff', marginRight: '10px', marginLeft: '10px'
    }

    const telefone = '(16) 99975-1411'
    const wpp = 'https://api.whatsapp.com/send?phone=' + '55' + telefone.replace(/\D/g, '');

    return (
        <div style={{ backgroundColor: '#23231A', padding: '50px' }}>

            {/* <a href='/' style={{ textDecoration: 'none', color: 'inherit' }}>
                <img src={logowhite} style={{ width: '150px', position: 'absolute' }} />
            </a> */}

            <div className='footer1'>
                <div className='footer2'>
                    <HiOutlineMail style={{ color: verde, fontSize: 30 }} />
                    <span style={contato}>contato@encurtagro.com</span>
                </div>

                <a target="_blank" href={wpp} className='footer2'>
                    <MdOutlineLocalPhone style={{ color: verde, marginLeft: 15, fontSize: 30 }} />
                    <span style={contato}>{telefone}</span>
                </a>

                <a target="_blank" href="https://www.instagram.com/encurtagro/" className='footer2'>
                    <FaInstagram style={{ color: verde, marginLeft: 15, fontSize: 30 }} />
                    <span style={contato}>encurtagro</span>
                </a>
            </div>

            <div className="termos_footer">
                <a href="termos" style={{ textDecoration: 'none' }}>
                    <span style={termos}>TERMOS E CONDIÇÕES DE USO</span>
                </a>
                <a href="privacidade" className="termos_footer_privacidade" style={{ textDecoration: 'none' }}>
                    <span style={termos}>POLÍTICA DE PRIVACIDADE</span>
                </a>
            </div>


        </div>
    );
}
