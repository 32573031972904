import React, { useState, useEffect } from 'react';
import fundo1 from '../img/fundo1.png'
import Server from '../Servidor';
import Header from './Header';
import Footer from '../Footer';
import fundo4 from '../img/fundo44.png';
import { CiImageOn } from "react-icons/ci";
import cel from '../img/celwhite.png'
import { IoMdClose } from "react-icons/io";
import { useLocation } from 'react-router-dom';
import CardProduto from '../Modelo1/CardProduto';
import CarrinhoFlutuante from './CarrinhoFlutuante';
import { useNavigate } from 'react-router-dom';
import { server_url } from '../config';
import { useDados } from "../context/Dados";
import { IoMdCloudUpload } from "react-icons/io";
import { FaCheckCircle, FaExclamationCircle } from "react-icons/fa";
import blockchain1 from '../../src/img/blockchain1.jpg'

export default function BlockChain() {
    const navigate = useNavigate();

    var { dadosmain } = useDados()
    if (dadosmain.length == 0) {
        dadosmain.push([])
    }

    const [load0, setload0] = useState(true)
    const [file, setFile] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState("");
    const [uploadStatus, setUploadStatus] = useState(""); // Success or Error
    const [link, setlink] = useState("")

    const MAX_FILE_SIZE = 50 * 1024 * 1024;

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            // Verifica o tamanho do arquivo
            if (selectedFile.size > MAX_FILE_SIZE) {
                setMessage("O arquivo é muito grande. O limite é 50MB.");
                setFile(null); // Limpa o arquivo se for muito grande
                setUploadStatus("");
            } else {
                setFile(selectedFile);
                setMessage(""); // Limpa mensagens anteriores
                setUploadStatus("");
            }
        }
    };

    const fileToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    };

    const enviarArquivo = async () => {
        if (!file) {
            setMessage("Por favor, selecione um arquivo antes de enviar.");
            setUploadStatus("error");
            return;
        } else {

            const base64File = await fileToBase64(file); // Converte o arquivo para Base64
            setIsLoading(true)

            try {
                const resposta = await Server({ codigo: "upload_blockchain", arquivo: base64File, nomeArquivo: file.name });

                if (resposta.error) {
                    console.error(resposta.error);
                    setMessage("Erro ao enviar o arquivo. Tente novamente.");
                    setlink("")
                    setIsLoading(false);
                    setFile(null)
                    setUploadStatus("error");
                } else {
                    setMessage("Arquivo enviado com sucesso!");
                    setUploadStatus("success");
                    setlink(resposta.transactionId)
                    setIsLoading(false)
                    setFile(null)
                }

            } catch (error) {
                console.error("Erro ao fazer a requisição:", error);
                setMessage("Erro ao enviar o arquivo. Tente novamente.");
                setUploadStatus("error");
                setlink("")
                setIsLoading(false);
                setFile(null)
            }

            // setTimeout(() => {
            //     setMessage("Upload concluído com sucesso!");
            //     setlink('dsafsfdsfsdfsdsasa')
            //     setFile(null)
            //     setUploadStatus("success");
            //     setIsLoading(false);
            // }, 2000);
        }
    };

    const storedDataString = localStorage.getItem("dados");
    const storedData = JSON.parse(storedDataString);

    useEffect(() => {
        async function Inicio() {
            const currentTime = new Date().getTime();
            const last = storedData ? storedData.last : 0;
            const timeDifference = currentTime - last;

            if (timeDifference >= 24 * 60 * 60 * 1000) {
                if (storedData) {
                    //bota um timer aqui
                    //so faz o login se passar 24h
                    //se entrar aqui faz login e atualiza o store
                    const resposta2 = await Server({ codigo: 'login', email: storedData.login, senha: storedData.senha })

                    if (resposta2.error) {
                        localStorage.removeItem("dados");
                        setload0(false)
                    } else {
                        const all = resposta2.userinfo
                        const dadosasync = {
                            login: storedData.login,
                            senha: storedData.senha,
                            last: currentTime,
                            ...all
                        }
                        const allString = JSON.stringify(dadosasync);
                        localStorage.setItem("dados", allString);
                        dadosmain[0].dados = all
                        setload0(false)
                    }
                } else {
                    dadosmain[0].dados = storedData
                    setload0(false)
                }
            } else {
                localStorage.removeItem("dados");
                setload0(false)
            }
        }
        Inicio()
    }, []);

    return (
        load0 ?
            <div style={{ height: '100vh', width: '100vw', background: '#fff', alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                <span className="load_inicial" />
            </div>
            :
            <div style={{ fontFamily: "Arial, sans-serif", backgroundColor: "#f4f4f9", color: "#333", minHeight: "100vh" }}>
                <Header mostrar_regiao={false} nome={storedData ? storedData.nome : null} />

                {/* Introdução sobre Blockchain */}
                <section style={{ padding: "40px 20px", textAlign: "center", backgroundColor: "#2A4951", color: "#fff" }}>
                    <h1 style={{ fontSize: "36px", marginBottom: "20px" }}>O que é Blockchain?</h1>
                    <p style={{ fontSize: "18px", maxWidth: "800px", margin: "0 auto" }}>
                        A blockchain é uma tecnologia que oferece segurança, transparência e rastreabilidade. Ela é amplamente utilizada em diversas áreas, como transações financeiras e rastreamento de produtos. Quando você faz upload de um arquivo — seja uma foto, vídeo, PDF ou outro formato — ele se torna permanente, não podendo ser alterado ou excluído. Isso garante uma camada extra de confiança e proteção, ideal para armazenar informações importantes com total segurança.
                    </p>
                    <img
                        src={blockchain1}
                        alt="Blockchain illustration"
                        style={{ width: "80%", maxWidth: "350px", marginTop: "35px", borderRadius: "10px" }}
                    />
                </section>

                {/* como funciona */}
                <section style={{ padding: "40px 20px", textAlign: "center", backgroundColor: "#588157", color: "#fff" }}>
                    <h1 style={{ fontSize: "36px", marginBottom: "20px" }}>Como funciona?</h1>
                    <p style={{ fontSize: "18px", maxWidth: "800px", margin: "0 auto", marginBottom: '20px' }}>
                        Envie qualquer arquivo pelo botão abaixo e nós o colocaremos na blockchain para você. Assim que o processo for concluído, você receberá um link exclusivo para acessar o seu arquivo, garantindo segurança, transparência e disponibilidade permanente.
                    </p>
                </section>




                {/* Upload de Arquivo */}
                <section style={{ padding: "40px 20px", backgroundColor: "#f9f9f9" }}>
                    <h2 style={{ fontSize: "28px", textAlign: "center", marginBottom: "20px" }}>Upload de Arquivo</h2>
                    <div style={{ maxWidth: "600px", margin: "0 auto", textAlign: "center" }}>
                        <div style={{ marginBottom: "20px" }}>
                            <label
                                htmlFor="file-upload"
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    padding: "20px",
                                    border: "2px dashed #ccc",
                                    borderRadius: "10px",
                                    background: isLoading ? "#f0f0f0" : "#f7f7f7", // Desabilita visualmente
                                    cursor: isLoading ? "not-allowed" : "pointer",
                                }}
                            >
                                <IoMdCloudUpload size={50} color={isLoading ? "#aaa" : "#007bff"} />
                                <span style={{ marginTop: "10px", color: isLoading ? "#aaa" : "#777" }}>
                                    {file ? file.name : "Clique ou arraste um arquivo aqui"}
                                </span>
                            </label>
                            <input
                                id="file-upload"
                                type="file"
                                onChange={handleFileChange}
                                style={{ display: "none" }}
                                disabled={isLoading} // Desativa a seleção
                            />
                        </div>

                        <button
                            onClick={enviarArquivo}
                            disabled={isLoading} // Desativa o botão enquanto o upload está em andamento
                            style={{
                                padding: "10px 20px",
                                background: isLoading ? "#ccc" : "#007bff",
                                color: "#fff",
                                border: "none",
                                borderRadius: "5px",
                                cursor: isLoading ? "not-allowed" : "pointer",
                                fontSize: "16px",
                            }}
                        >
                            {isLoading ? "Enviando..." : "Enviar"}
                        </button>

                        {/* Loader */}
                        {isLoading && (
                            <div style={{ marginTop: "20px" }}>
                                <span className="loader"></span>
                                <p style={{ marginTop: "10px", color: "#555" }}>Processando upload...</p>
                            </div>
                        )}

                        {/* Mensagens de sucesso ou erro */}
                        {message && (
                            <div style={{
                                marginTop: "20px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                color: uploadStatus === "success" ? "green" : "red",
                            }}>
                                {uploadStatus === "success" ? <FaCheckCircle size={20} /> : <FaExclamationCircle size={20} />}
                                <span style={{ marginLeft: "10px", fontSize: 18 }}>{message}</span>
                            </div>
                        )}

                        {/* Exibe o link do arquivo após o upload */}
                        {uploadStatus === "success" && (
                            <div style={{ marginTop: "20px", textAlign: "center" }}>
                                <a
                                    href={`https://arweave.net/${link}`} // Substitua `transactionId` pela resposta do servidor
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{
                                        display: "inline-block",
                                        padding: "10px 20px",
                                        background: "#007bff",
                                        color: "#fff",
                                        textDecoration: "none",
                                        borderRadius: "5px",
                                        marginTop: "10px",
                                        fontSize: "16px",
                                    }}
                                >
                                    Visualizar Arquivo
                                </a>
                                <p style={{ marginTop: "10px", color: "#555" }}>
                                    <strong>Link:</strong> https://arweave.net/{link}
                                </p>
                            </div>
                        )}
                    </div>
                </section>


                <Footer />
            </div>
    );
}
