import React, { useState, useEffect } from 'react';
import fundo1 from '../img/fundo1.png'
import Server from '../Servidor';
import Header from './Header';
import Footer from '../Footer';
import { useLocation } from 'react-router-dom';
import { CiImageOn } from "react-icons/ci";
import { FiPlus, FiMinus, FiTrash } from "react-icons/fi";
import { FaSeedling } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { MdOutlineLocalPhone } from "react-icons/md";
import { IoMdClose } from "react-icons/io";
import Modal from './Modal';
import { FaWhatsapp } from "react-icons/fa";
import CarrinhoFlutuante from './CarrinhoFlutuante';
import { useParams } from 'react-router-dom';
import { useDados } from "../context/Dados";

export default function DetalhesProduto() {
    const { categoria, id, userforn } = useParams();

    var { dadosmain } = useDados()
    if (dadosmain.length == 0) {
        dadosmain.push([])
    }

    const branco = '#f8f6f4'

    const [load0, setload0] = useState(true)

    const [sacola, setsacola] = useState([])
    // var { dadosmain } = useDados()
    // if (dadosmain.length == 0) {
    //     dadosmain.push([])
    // }
    // const dados = dadosmain[0].dados
    //usa pra calcular taxa de entrega, apenas
    //se ele mudar o endereço no app é um problema, pq ai vai usar os dados antigos aqui
    //pra corrigir só se fizesse o login dnv toda vez que mudar de página

    const [att, setatt] = useState(0)

    const location = useLocation();
    const item0 = location.state || false
    const [item, setitem] = useState(item0 ? item0.item : false)

    const [load, setload] = useState(true)
    const [res, setres] = useState([])
    const [anuncio, setanuncio] = useState(false)

    async function pegarDadosforn() {
        try {
            const resposta = await Server({ codigo: 'pegar_dadosforn', usuario: item.userforn });

            if (resposta.error) {
                console.log(resposta.error)
            } else {
                setres(resposta.dadosforn)
                setload(false)
            }
        } catch (error) {
            console.log(error)
        }
    }

    async function pegarItem() {
        try {
            const resposta0 = await Server({ codigo: 'pegar_produto_ou_anuncio_url_site', id, userforn, categoria });

            if (resposta0.error) {
                console.log(resposta0.error)
            } else {
                if (resposta0.item.anuncio) {
                    setitem(resposta0.item)
                    setload(false)
                } else {
                    const resposta = await Server({ codigo: 'pegar_dadosforn', usuario: resposta0.item.userforn });

                    if (resposta.error) {
                        console.log(resposta.error)
                    } else {
                        setitem(resposta0.item)
                        setres(resposta.dadosforn)
                        setload(false)
                    }
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    // useEffect(() => {
    //     if (item) {
    //         if (item.anuncio) {
    //             setload(false)
    //             setanuncio(true)
    //         } else {
    //             pegarDadosforn()
    //         }
    //     } else {
    //         //veio de url externa
    //         pegarItem()
    //     }
    // }, []);

    const storedDataString = localStorage.getItem("dados");
    const storedData = JSON.parse(storedDataString);

    useEffect(() => {
        async function Inicio() {
            const currentTime = new Date().getTime();
            const last = storedData ? storedData.last : 0;
            const timeDifference = currentTime - last;

            if (timeDifference >= 24 * 60 * 60 * 1000) {
                //bota um timer aqui
                //so faz o login se passar 24h
                //se entrar aqui faz login e atualiza o store
                if (storedData) {
                    const resposta2 = await Server({ codigo: 'login', email: storedData.login, senha: storedData.senha })

                    if (resposta2.error) {
                        localStorage.removeItem("dados");
                        setload0(false)
                        if (item) {
                            if (item.anuncio) {
                                setload(false)
                                setanuncio(true)
                            } else {
                                pegarDadosforn()
                            }
                        } else {
                            //veio de url externa
                            pegarItem()
                        }
                    } else {
                        const all = resposta2.userinfo
                        const dadosasync = {
                            login: storedData.login,
                            senha: storedData.senha,
                            last: currentTime,
                            ...all
                        }
                        const allString = JSON.stringify(dadosasync);
                        localStorage.setItem("dados", allString);
                        dadosmain[0].dados = all
                        setload0(false)
                        if (item) {
                            if (item.anuncio) {
                                setload(false)
                                setanuncio(true)
                            } else {
                                pegarDadosforn()
                            }
                        } else {
                            //veio de url externa
                            pegarItem()
                        }
                    }
                } else {
                    setload0(false)
                    if (item) {
                        if (item.anuncio) {
                            setload(false)
                            setanuncio(true)
                        } else {
                            pegarDadosforn()
                        }
                    } else {
                        //veio de url externa
                        pegarItem()
                    }
                }
            } else {
                setload0(false)
                if (item) {
                    if (item.anuncio) {
                        setload(false)
                        setanuncio(true)
                    } else {
                        pegarDadosforn()
                    }
                } else {
                    //veio de url externa
                    pegarItem()
                }
            }
        }
        Inicio()
    }, []);

    function Produto() {
        const [opcao, setopcao] = useState(item.opcoes ? Object.keys(item.opcoes)[0].opcao : false)
        const [qtd, setqtd] = useState(item.minimo ? parseInt(item.minimo) : 1)
        const [display_infos_produtor, set_display_infos_produtor] = useState(false)
        const [modalerro, setmodalerro] = useState(false)

        function Menos() {
            if (qtd > 1) {
                if (item.minimo) {
                    if (qtd > parseInt(item.minimo)) {
                        setqtd(qtd - 1)
                    }
                } else {
                    setqtd(qtd - 1)
                }
            }
        }

        function Mais() {
            if (item.estoque) {
                if (qtd < parseInt(item.estoque)) {
                    setqtd(qtd + 1)
                }
            } else {
                setqtd(qtd + 1)
            }
        }

        function ChangeOpcao(itemString) {
            const item = JSON.parse(itemString);
            setopcao(item.opcao)
        }

        const produtor = item.produtorid ? res.extensoes.produtores[item.produtorid] : null

        function Adicionar() {
            //veririca se tem item de outro fornecedor
            if (sacola.length == 0) {
                //sacola.push({ infos: item, qtd, opcao })
                //set_display_carrinho(true)
                const sacolaStore0 = JSON.parse(localStorage.getItem("sacola")) || [];

                // Cria o novo item
                const sacolaasync = {
                    infos: item,
                    qtd,
                    opcao,
                    dadosforn: res
                };

                // Adiciona o novo item ao array existente
                sacolaStore0.push(sacolaasync);

                // Salva o array atualizado no localStorage
                localStorage.setItem("sacola", JSON.stringify(sacolaStore0));

                setatt(att + 1)
            } else {
                if (sacola[0].infos.userforn != item.userforn) {
                    setmodalerro(true)
                } else {
                    const sacolaStore0 = JSON.parse(localStorage.getItem("sacola")) || [];

                    // Cria o novo item
                    const sacolaasync = {
                        infos: item,
                        qtd,
                        opcao,
                    };

                    // Adiciona o novo item ao array existente
                    sacolaStore0.push(sacolaasync);

                    // Salva o array atualizado no localStorage
                    localStorage.setItem("sacola", JSON.stringify(sacolaStore0));

                    setatt(att + 1)
                }
            }
        }

        const entrega = res.extensoes?.delivery?.entrega?.search('1') > -1 ? true : false
        const retirada = res.extensoes?.delivery?.entrega?.search('2') > -1 ? true : false

        //calcula a taxa de entrega kkkkkkk
        var taxapreco = 0

        if (storedData) {
            if (res.extensoes?.delivery?.taxafixa || res.extensoes?.delivery?.taxavariavel) {
                if (res.extensoes?.delivery?.taxafixa) {
                    taxapreco = res.extensoes?.delivery?.taxafixa

                    if (res.extensoes?.delivery?.kmgratis) {
                        var lat2 = storedData.latitude;
                        var lon2 = storedData.longitude;
                        var lat1 = res.extensoes?.delivery?.latitude;
                        var lon1 = res.extensoes?.delivery?.longitude;

                        var R = 6371;
                        var x1 = lat2 - lat1;

                        function toRad(Value) {
                            return Value * Math.PI / 180;
                        }

                        var dLat = toRad(x1);
                        var x2 = lon2 - lon1;
                        var dLon = toRad(x2);
                        var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
                        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
                        var d = R * c;

                        if (d < res.extensoes?.delivery?.kmgratis) {
                            taxapreco = 0
                        }
                    }
                } else {
                    //possui taxa variável
                    //verifica se tem valor inicial pra somar
                    var lat2 = storedData.latitude;
                    var lon2 = storedData.longitude;
                    var lat1 = res.extensoes?.delivery?.latitude;
                    var lon1 = res.extensoes?.delivery?.longitude;

                    var R = 6371;
                    var x1 = lat2 - lat1;

                    function toRad(Value) {
                        return Value * Math.PI / 180;
                    }

                    var dLat = toRad(x1);
                    var x2 = lon2 - lon1;
                    var dLon = toRad(x2);
                    var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
                    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
                    var d = R * c;

                    taxapreco = (parseFloat(d) * parseFloat(res.extensoes?.delivery?.taxavariavel)).toFixed(2)

                    if (res.extensoes?.delivery?.kmgratis) {
                        if (d < res.extensoes?.delivery?.kmgratis) {
                            taxapreco = 0
                        }
                    }

                    if (res.extensoes?.delivery?.taxavariavelinicial) {
                        taxapreco = parseFloat(taxapreco) + parseFloat(res.extensoes?.delivery?.taxavariavelinicial)
                    }

                }
            }
        }

        function MostrarDetalhesProdutor() {
            const wpp_produtor = 'https://api.whatsapp.com/send?phone=' + '55' + item.produtorid.replace(/\D/g, '');
            const insta_produtor = 'https://www.instagram.com/' + produtor.instagram

            return (
                <div className="home_modal" onClick={() => set_display_infos_produtor(false)}>
                    <div className='home_modal_regiao' onClick={(e) => e.stopPropagation()}>
                        <IoMdClose className="iconex" onClick={() => set_display_infos_produtor(false)} />

                        <FaSeedling className="seedling" />
                        <h1 style={{ fontSize: 26, fontFamily: 'PatrickHand', color: '#fff' }}>{produtor.nome}</h1>
                        {produtor.descricao ?
                            <span style={{ fontSize: 21, fontFamily: 'Delius', color: branco, marginTop: 25, textAlign: 'center' }}>{produtor.descricao}</span>
                            : null}

                        <div style={{ display: 'flex', flexDirection: 'row', marginTop: 30 }}>
                            <a target="_blank" href={wpp_produtor} style={{ display: 'flex', textDecoration: 'none', alignItems: 'center' }}>
                                <MdOutlineLocalPhone className="phone" />
                                <h3 className="contato">{produtor.telefone}</h3>
                            </a>

                            {produtor.instagram ?
                                <a target="_blank" href={insta_produtor} style={{ display: 'flex', textDecoration: 'none', alignItems: 'center', wordWrap: 'break-word' }}>
                                    <FaInstagram className="phone" />
                                    <h3 className="contato">{produtor.instagram}</h3>
                                </a>
                                : null}
                        </div>
                    </div>
                </div>
            )
        }

        return (
            <div className='detalhes_produto_div'>
                <div className='detalhes_produto_div2'>
                    {item.icone ?
                        <img src={item.icone} className='detalhes_produto_img' />
                        : <CiImageOn className='detalhes_produto_icone' />}

                    <div className='detalhes_produto_div3'>
                        <h2>{item.titulo}</h2>
                        <span style={{ marginTop: 10, fontSize: 20, marginBottom: 5 }}>{item.tamanho}</span>

                        {item.opcoes ?
                            <select className="select" onChange={(e) => ChangeOpcao(e.target.value)}>
                                {item.opcoes.map(item => (
                                    <option
                                        key={item.opcao}
                                        value={JSON.stringify(item)}>{item.opcao}
                                    </option>
                                ))}
                            </select>
                            : null}

                        <div className="quantidade_container">
                            <button className="btn_quantidade" onClick={() => Menos()}>
                                <FiMinus style={{ fontSize: 25 }} />
                            </button>
                            <span style={{ marginLeft: 20, marginRight: 20 }}>{qtd}</span>
                            <button className="btn_quantidade" onClick={() => Mais()}>
                                <FiPlus style={{ fontSize: 25 }} />
                            </button>
                        </div>

                        {item.status ?
                            <button className="btn_fechado" style={{ width: '100%' }}>Indisponível</button>
                            :
                            <button onClick={() => Adicionar()} className="btn_adicionar" style={{ width: '100%' }}>Adicionar (R$ {(item.minimopromo ? qtd >= item.minimopromo ? item.precopromo * qtd : item.preco * qtd : item.preco * qtd).toFixed(2)})</button>
                        }
                        {item.minimo ?
                            <div className="continuar_compra_valores">
                                <span className="modalproduto_estoque">Pedido mínimo: </span>
                                <span className="modalproduto_estoque">{item.minimo}</span>
                            </div>
                            : null}

                        {item.estoque ?
                            <div className="continuar_compra_valores">
                                <span className="modalproduto_estoque">Estoque: </span>
                                <span className="modalproduto_estoque">{item.estoque}</span>
                            </div>
                            : null}

                        {produtor ?
                            <span onClick={() => set_display_infos_produtor(true)} className="produtor_nome" style={{ textAlign: 'center' }}>{produtor.nome}</span>
                            : null}

                        {item.precopromo ?
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <span className="promocao_titulo">Promoção</span>
                                {item.minimopromo == 1 ?
                                    <span style={{ marginTop: 5 }}>{Math.round(100 - ((parseFloat(item.precopromo) * 100) / parseFloat(item.preco)))}% de desconto</span>
                                    :
                                    <span style={{ marginTop: 5 }}>{Math.round(100 - ((parseFloat(item.precopromo) * 100) / parseFloat(item.preco)))}% de desconto para pedidos acima de {item.minimopromo} uni</span>
                                }
                            </div>
                            : null}
                    </div>
                </div>

                {display_infos_produtor ?
                    <MostrarDetalhesProdutor />
                    : null}

                {item.descricao ?
                    <div style={{ marginTop: 20 }}>
                        <h3 style={{ marginBottom: 10 }}>Descrição</h3>
                        <span className="modalproduto_descricao">{item.descricao}</span>
                    </div>
                    : null}

                <h2 style={{ fontSize: 20, textAlign: 'center', marginTop: 20, marginBottom: 20 }}>Informações do vendedor</h2>

                <h3 style={{ marginBottom: 10 }}>Nome</h3>
                <span className="modalproduto_descricao">{res.nomeforn}</span>

                {res.extensoes?.site?.url ?
                    <div style={{ marginTop: 20 }}>
                        <h3 style={{ marginBottom: 10 }}>Loja online</h3>
                        <div className='home_acessarloja'>
                            <a href={'/' + res.extensoes.site.url} target="_blank">Acessar</a>
                        </div>
                    </div>
                    : null}

                {(entrega || retirada) ?
                    <div style={{ marginTop: 20 }}>
                        <h3 style={{ marginBottom: 10 }}>Formas de entrega</h3>

                        <div>
                            {entrega ?
                                <div>
                                    <span className="modalproduto_descricao">Entrega</span>
                                </div>
                                : null}

                            {retirada ?
                                <div>
                                    <span className="modalproduto_descricao">Retirada no local</span>
                                </div>
                                : null}
                        </div>

                        {entrega ?

                            <div>
                                {res.extensoes?.delivery?.diasentrega ?
                                    <div style={{ marginTop: 20 }}>
                                        <h3 style={{ marginBottom: 10 }}>Dias de entrega</h3>

                                        {res.extensoes?.delivery?.diasentrega?.search('1') > -1 ?
                                            <span>Domingo</span>
                                            : null}
                                        {res.extensoes?.delivery?.diasentrega?.search('2') > -1 ?
                                            <span>Segunda</span>
                                            : null}
                                        {res.extensoes?.delivery?.diasentrega?.search('3') > -1 ?
                                            <span>Terça</span>
                                            : null}
                                        {res.extensoes?.delivery?.diasentrega?.search('4') > -1 ?
                                            <span>Quarta</span>
                                            : null}
                                        {res.extensoes?.delivery?.diasentrega?.search('5') > -1 ?
                                            <span>Quinta</span>
                                            : null}
                                        {res.extensoes?.delivery?.diasentrega?.search('6') > -1 ?
                                            <span>Sexta</span>
                                            : null}
                                        {res.extensoes?.delivery?.diasentrega?.search('7') > -1 ?
                                            <span>Sábado</span>
                                            : null}
                                    </div>
                                    : null}


                                {res.extensoes?.delivery?.horarioentrega ?
                                    <div style={{ marginTop: 20 }}>
                                        <h3 style={{ marginBottom: 10 }}>Horário de entrega</h3>
                                        <span>{res.extensoes?.delivery?.horarioentrega}</span>
                                    </div>
                                    : null}

                                <div style={{ marginTop: 20 }}>
                                    <h3 style={{ marginBottom: 10 }}>Taxa de entrega</h3>
                                    <span>{storedData ? 'R$ ' + taxapreco : 'Faça login para visualizar a taxa de entrega'}</span>

                                    <div style={{ marginTop: 10 }}>
                                        {res.extensoes?.delivery?.pedidoentregagratis ?
                                            <span style={{ marginBottom: 10, marginTop: 20 }}>* Entrega grátis para compras acima de R$ {res.extensoes?.delivery?.pedidoentregagratis}</span>
                                            : null}
                                    </div>
                                </div>

                            </div>
                            : null}

                        {retirada ?
                            <div>
                                <div style={{ marginTop: 20 }}>
                                    <h3 style={{ marginBottom: 10 }}>Dias para retirada</h3>

                                    {res.extensoes?.delivery?.diasretirada?.search('1') > -1 ?
                                        <span>Domingo</span>
                                        : null}
                                    {res.extensoes?.delivery?.diasretirada?.search('2') > -1 ?
                                        <span>Segunda</span>
                                        : null}
                                    {res.extensoes?.delivery?.diasretirada?.search('3') > -1 ?
                                        <span>Terça</span>
                                        : null}
                                    {res.extensoes?.delivery?.diasretirada?.search('4') > -1 ?
                                        <span>Quarta</span>
                                        : null}
                                    {res.extensoes?.delivery?.diasretirada?.search('5') > -1 ?
                                        <span>Quinta</span>
                                        : null}
                                    {res.extensoes?.delivery?.diasretirada?.search('6') > -1 ?
                                        <span>Sexta</span>
                                        : null}
                                    {res.extensoes?.delivery?.diasretirada?.search('7') > -1 ?
                                        <span>Sábado</span>
                                        : null}
                                </div>

                                <div style={{ marginTop: 20 }}>
                                    <h3 style={{ marginBottom: 10 }}>Horário para retirada</h3>
                                    <span>{res.extensoes?.delivery?.horarioretirada}</span>
                                </div>

                                <div style={{ marginTop: 20 }}>
                                    <h3 style={{ marginBottom: 10 }}>Endereço para retirada</h3>
                                    <span>{res.extensoes?.delivery?.retiradaend}</span>
                                </div>

                            </div>
                            : null}


                    </div>
                    : null}

                {modalerro ?
                    <Modal
                        text={'Você já possui produtos de outro fornecedor no carrinho'}
                        close={() => setmodalerro(false)}
                    />
                    : null}
            </div>
        )
    }

    function Anuncio() {
        const newtelefone = '55' + item.telefone.replace(/\D/g, '');
        return (
            <div style={{ marginTop: 50, background: 'rgb(255,255,255)', padding: 50, borderRadius: 10, marginBottom: 50 }}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    {item.icone ?
                        <img src={item.icone} style={{ width: 400, borderRadius: 20 }} />
                        : <CiImageOn style={{ fontSize: 300 }} />}

                    <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 50 }}>
                        <h2>{item.titulo}</h2>
                        <span style={{ marginTop: 10, fontSize: 20, marginBottom: 5 }}>{item.tamanho}</span>

                        <span style={{ marginTop: 10, fontSize: 22, fontWeight: 700, marginBottom: 5 }}>R$ {item.preco}</span>

                        <div className='home_btn_anunciante' onClick={() => window.open('http://api.whatsapp.com/send?phone=' + newtelefone, "_blank")}>
                            <FaWhatsapp style={{ fontSize: 26, color: '#fff' }} />

                            <span style={{ color: '#fff', marginLeft: 10, fontSize: 20 }}>Falar com o anunciante</span>
                        </div>

                    </div>
                </div>

                {item.descricao ?
                    <div style={{ marginTop: 20 }}>
                        <h3 style={{ marginBottom: 10 }}>Descrição</h3>
                        <span className="modalproduto_descricao">{item.descricao}</span>
                    </div>
                    : null}

                <h3 style={{ marginBottom: 10, marginTop: 20 }}>Publicado</h3>
                <span className="modalproduto_descricao">{item.publicado}</span>

                <h3 style={{ marginBottom: 10, marginTop: 20 }}>Vendedor</h3>
                <span className="modalproduto_descricao">{item.nome}</span>

                <h3 style={{ marginBottom: 10, marginTop: 20 }}>Origem</h3>
                <span className="modalproduto_descricao">{item.origem}</span>

                <h3 style={{ marginBottom: 10, marginTop: 20 }}>Contato</h3>
                <span className="modalproduto_descricao">{item.telefone}</span>


            </div>
        )
    }

    return (
        load0 ?
            <div style={{ height: '100vh', width: '100vw', background: '#fff', alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                <span className="load_inicial" />
            </div>
            :
            <div>
                <Header
                    mostrar_regiao={false}
                    nome={storedData ? storedData.nome : null}
                />

                <div style={{ minHeight: 700, display: 'flex', justifyContent: 'center', background: 'rgb(242, 242, 242)' }}>

                    {load ?
                        <span className="load_inicial" />
                        :
                        anuncio ?
                            <Anuncio />
                            :
                            <Produto />
                    }
                </div>

                <CarrinhoFlutuante abrir={att} />

                <Footer />
            </div>
    );
}
