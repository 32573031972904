import React, { useState, useEffect } from 'react';
import { IoMdClose } from "react-icons/io";
import { useDados } from "../context/Dados";
import { FiPlus, FiMinus, FiTrash } from "react-icons/fi";
import { CiImageOn } from "react-icons/ci";
import EntrarConta from '../Modelo1/EntrarConta';
import CriarConta from '../Modelo1/CriarConta';
import Perfil from '../Modelo1/Perfil';
import { FiUser } from "react-icons/fi";
import ContinuarCompra from '../Modelo1/ContinuarCompra';

export default function CarrinhoFlutuante({ abrir }) {

    var { dadosmain } = useDados()
    if (dadosmain.length == 0) {
        dadosmain.push([])
    }
    const dados = dadosmain[0].dados

    // const sacolaStore0 = localStorage.getItem("sacola");
    // const sacola1 = JSON.parse(sacolaStore0);
    // const sacola = Object.values(sacola1);
    const sacola = Object.values(JSON.parse(localStorage.getItem("sacola") || "[]"));

    //localStorage.removeItem("sacola");

    const [att, setatt] = useState(0)
    const [display_carrinho, set_display_carrinho] = useState(false)
    const [display_entrar_criar, setdisplay_entrar_criar] = useState(false)
    const [display_entrar, setdisplay_entrar] = useState(false)
    const [display_criar, setdisplay_criar] = useState(false)
    const [display_perfil, setdisplay_perfil] = useState(false)
    const [display_continuar_compra, set_display_continuar_compra] = useState(false)

    useEffect(() => {
        if (abrir > 0) {
            set_display_carrinho(true);
        }
    }, [abrir]);

    var total = 0
    if (Array.isArray(sacola) && sacola.length > 0) {
        sacola.forEach((item) => {
            if (item.infos.minimopromo) {
                if (item.qtd >= item.infos.minimopromo) {
                    total = total + item.infos.precopromo * item.qtd
                } else {
                    total = total + item.infos.preco * item.qtd
                }
            } else {
                total = total + item.infos.preco * item.qtd
            }
        })
    }

    function VoltarCriarConta() {
        setdisplay_criar(false)
        setdisplay_entrar_criar(true)
    }

    function VoltarEntrarConta() {
        setdisplay_entrar(false)
        setdisplay_entrar_criar(true)
    }


    function ConteudoCarrinho({ item, index }) {
        const [qtd, setqtd] = useState(item.qtd)

        function Menos() {
            if (qtd > 1) {
                if (item.infos.minimo) {
                    if (qtd > parseInt(item.infos.minimo)) {
                        setqtd(qtd - 1);
                        atualizarSacola(index, qtd - 1); // Atualiza a quantidade no localStorage
                        setatt(att + 1);
                    }
                } else {
                    setqtd(qtd - 1);
                    atualizarSacola(index, qtd - 1);
                    setatt(att + 1);
                }
            }
        }

        function Mais() {
            if (item.infos.estoque) {
                if (qtd < parseInt(item.infos.estoque)) {
                    setqtd(qtd + 1);
                    atualizarSacola(index, qtd + 1); // Atualiza a quantidade no localStorage
                    setatt(att + 1);
                }
            } else {
                setqtd(qtd + 1);
                atualizarSacola(index, qtd + 1);
                setatt(att + 1);
            }
        }

        function Remover() {
            removerItemDaSacola(index); // Remove o item do localStorage
            setatt(att + 1);
        }

        // Função para atualizar a quantidade de um item na sacola
        function atualizarSacola(index, novaQuantidade) {
            const sacolaStore0 = localStorage.getItem("sacola");
            const sacola = sacolaStore0 ? JSON.parse(sacolaStore0) : [];

            if (sacola[index]) {
                sacola[index].qtd = novaQuantidade; // Atualiza a quantidade
                localStorage.setItem("sacola", JSON.stringify(sacola)); // Salva a sacola atualizada
            }
        }

        // Função para remover um item da sacola
        function removerItemDaSacola(index) {
            const sacolaStore0 = localStorage.getItem("sacola");
            const sacola = sacolaStore0 ? JSON.parse(sacolaStore0) : [];

            if (index >= 0 && index < sacola.length) {
                sacola.splice(index, 1); // Remove o item
                localStorage.setItem("sacola", JSON.stringify(sacola)); // Salva a sacola atualizada
            }
        }


        return (
            <div style={{ display: 'flex', margin: 10 }}>
                <div style={{ width: '20%' }}>
                    {item.infos.icone ? (
                        <img style={{
                            width: '100%',
                            borderRadius: 15
                        }} src={item.infos.icone} alt="Imagem do produto" />
                    ) : (
                        <div style={{ width: '100%', height: 80, alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                            <CiImageOn style={{ fontSize: 60 }} />
                        </div>
                    )}
                </div>
                <div style={{ paddingLeft: 10 }}>
                    <div>
                        <span style={{ fontSize: 16, fontWeight: 900, fontFamily: 'Delius' }}>{item.infos.titulo}</span>
                    </div>
                    <div>
                        <span style={{ fontSize: 16, fontWeight: 900, fontFamily: 'Delius' }}>{item.infos.tamanho}</span>
                        {item.opcao ?
                            <span style={{ fontSize: 16, fontWeight: 900, fontFamily: 'Delius' }}>{item.opcao}</span>
                            : null}
                    </div>
                    <div>
                        <span style={{ fontSize: 16, marginTop: 10, fontWeight: 700, color: '#55a630', fontFamily: 'Delius' }}>R$ {item.infos.precopromo ? item.qtd >= item.infos.minimopromo ? item.infos.precopromo : item.infos.preco : item.infos.preco}</span>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'row' }}>

                        <div className="quantidade_container">
                            <button className="btn_quantidade" onClick={() => Menos()}>
                                <FiMinus style={{ fontSize: 17 }} />
                            </button>
                            <span style={{ marginLeft: 5, marginRight: 5, textAlign: 'center' }}>{qtd}</span>
                            <button className="btn_quantidade" onClick={() => Mais()}>
                                <FiPlus style={{ fontSize: 17 }} />
                            </button>
                        </div>
                        <FiTrash onClick={() => Remover()} style={{ fontSize: 17, padding: 10, cursor: 'pointer', position: 'absolute', right: 15 }} />

                    </div>

                </div>
            </div>
        )
    }

    function ContinuarCompraLogin() {
        set_display_continuar_compra(false)
        setdisplay_entrar_criar(true)
    }

    function PedidoFinalizado() {
        sacola.splice(0, sacola.length)
        setatt(att + 1)
    }

    return (
        <div>
            {sacola?.length >= 1 ?
                <div className="carrinho_flutuante" onClick={() => set_display_carrinho(true)}>
                    <button className="carrinho_flutuante_btn">
                        <span className="carrinho_flutuante_qtd">{sacola.length}</span>
                        <span className="carrinho_flutuante_text">Meu Pedido</span>
                        <span>R$ {total.toFixed(2)}</span>
                    </button>
                </div>
                : null}

            {display_carrinho ?
                <div className="home_modal" onClick={() => set_display_carrinho(false)}>
                    <div className={`carrinho ${display_carrinho ? 'carrinho-aberto' : ''}`} onClick={(e) => e.stopPropagation()}>

                        <div className="carrinho_area_fechar">
                            <IoMdClose className="carrinho_iconex" onClick={() => set_display_carrinho(false)} />
                        </div>

                        {sacola.length >= 1 ?
                            <div style={{ height: '100%' }}>
                                <div style={{ height: '75%', overflowY: 'auto' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        {sacola.map((item, index) => {
                                            return <ConteudoCarrinho key={index} item={item} index={index} />
                                        })}
                                    </div>
                                </div>

                                <div className="carrinho_footer">
                                    <span className="carrinho_footer_preco">R$ {total.toFixed(2)}</span>
                                    <button onClick={() => { set_display_carrinho(false); set_display_continuar_compra(true) }} className="carrinho_footer_btn">Continuar Compra</button>
                                </div>
                            </div>
                            : null}
                    </div>
                </div>
                : null}

            {display_entrar_criar ?
                <div className="home_modal" onClick={() => setdisplay_entrar_criar(false)}>
                    <div className='home_modal_regiao' onClick={(e) => e.stopPropagation()} style={{ animation: 'none' }}>
                        <IoMdClose className="iconex" onClick={() => setdisplay_entrar_criar(false)} />
                        <FiUser style={{ color: '#fff', fontSize: 60, marginBottom: 10 }} />

                        <span onClick={() => { setdisplay_entrar_criar(false); setdisplay_entrar(true) }} className="modal_conta_entrar_criar">Entrar</span>
                        <span onClick={() => { setdisplay_entrar_criar(false); setdisplay_criar(true) }} className="modal_conta_entrar_criar">Criar conta</span>
                    </div>
                </div>
                : null}

            {display_entrar ?
                <EntrarConta
                    voltar={() => VoltarEntrarConta()}
                    fechar={() => setdisplay_entrar(false)}
                />
                : null}

            {display_criar ?
                <CriarConta
                    voltar={() => VoltarCriarConta()}
                    fechar={() => setdisplay_criar(false)}
                />
                : null}

            {display_perfil ?
                <Perfil
                    fechar={() => setdisplay_perfil(false)}
                />
                : null}

            {display_continuar_compra ?
                <ContinuarCompra
                    fechar={() => set_display_continuar_compra(false)}
                    sacola={sacola}
                    total={total}
                    dadosforn={sacola[0].dadosforn}
                    login={() => ContinuarCompraLogin()}
                    finalizado={() => PedidoFinalizado()}
                />
                : null}


        </div>
    );
}
