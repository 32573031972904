import React, { useState, useEffect } from 'react';
import { IoMdClose } from "react-icons/io";
import { MdErrorOutline } from "react-icons/md";


const Modal = ({ close, text }) => (
    <div className="home_modal" onClick={close}>
        <div className='home_modal_erro' onClick={(e) => e.stopPropagation()}>
            <IoMdClose className="iconex" onClick={close} />

            <MdErrorOutline style={{ fontSize: 70, color: '#fff' }} />
            <h1 style={{ fontSize: 24, margin: 20, fontFamily: 'Delius', color: '#fff' }}>{text}</h1>

        </div>
    </div>
);

export default Modal;
