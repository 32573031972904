import React, { useState, useEffect } from 'react';
import fundo1 from '../img/fundo1.png'
import Server from '../Servidor';
import Header from './Header';
import Footer from '../Footer';
import fundo4 from '../img/fundo44.png';
import { CiImageOn } from "react-icons/ci";
import cel from '../img/celwhite.png'
import { IoMdClose } from "react-icons/io";
import CarrinhoFlutuante from './CarrinhoFlutuante';
import { useDados } from "../context/Dados";

export default function Lojas() {

    //todas as lojas, sem filtro de região (nem tem como filtro de região pelo formato que eu coloquei)

    var { dadosmain } = useDados()
    if (dadosmain.length == 0) {
        dadosmain.push([])
    }

    const divfundo = {
        height: '100%', width: '100%', flexDirection: 'column', backgroundImage: `url(${fundo4})`, backgroundSize: 'cover',
        display: 'flex', justifyContent: 'center'
    }

    const [load0, setload0] = useState(true)
    const [load, setload] = useState(true)
    const [res, setres] = useState([])
    const [modal_loja, setmodal_loja] = useState(false)

    async function pegarLojas() {
        try {
            const resposta = await Server({ codigo: 'pegar_lojas' });

            if (resposta.error) {
                console.log(resposta.error)
            } else {
                resposta.lojas.sort((a, b) => b.vendasforn - a.vendasforn);
                setres(resposta.lojas)
                setload(false)
            }

        } catch (error) {
            console.log(error)
        }
    }

    const storedDataString = localStorage.getItem("dados");
    const storedData = JSON.parse(storedDataString);

    useEffect(() => {
        async function Inicio() {
            const currentTime = new Date().getTime();
            const last = storedData ? storedData.last : 0;
            const timeDifference = currentTime - last;

            if (timeDifference >= 24 * 60 * 60 * 1000) {
                //bota um timer aqui
                //so faz o login se passar 24h
                //se entrar aqui faz login e atualiza o store
                if (storedData) {
                    const resposta2 = await Server({ codigo: 'login', email: storedData.login, senha: storedData.senha })

                    if (resposta2.error) {
                        localStorage.removeItem("dados");
                    } else {
                        const all = resposta2.userinfo
                        const dadosasync = {
                            login: storedData.login,
                            senha: storedData.senha,
                            last: currentTime,
                            ...all
                        }
                        const allString = JSON.stringify(dadosasync);
                        localStorage.setItem("dados", allString);
                        dadosmain[0].dados = all
                        setload0(false)
                        pegarLojas()
                    }
                } else {
                    dadosmain[0].dados = storedData
                    setload0(false)
                    pegarLojas()
                }
            } else {
                dadosmain[0].dados = storedData
                setload0(false)
                pegarLojas()
            }
        }
        Inicio()
    }, []);

    return (
        load0 ?
            <div style={{ height: '100vh', width: '100vw', background: '#fff', alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                <span className="load_inicial" />
            </div>
            :
            <div>
                <Header
                    mostrar_regiao={false}
                    nome={storedData ? storedData.nome : null}
                />

                <div style={divfundo}>
                    <div className='home_div_lojas'>
                        <h1>Lojas Encurtagro</h1>
                        <div className='home_criarloja'>
                            <button onClick={() => setmodal_loja(true)}>Criar uma loja</button>
                        </div>
                    </div>
                </div>

                <div className='home_produtos'>
                    {load ?
                        <span className="load_inicial" />
                        :
                        <div className="home_lojas_div">
                            {res.map((item) => (
                                <div className='home_lojas_divloja' onClick={() => window.open(`/${item.url}`, '_blank')}>
                                    {item.iconeforn ?
                                        <img src={item.iconeforn} className="home_lojas_iconeforn" />
                                        : <CiImageOn style={{ fontSize: 100 }} />
                                    }

                                    <h1 className="home_lojas_nomeforn">{item.nomeforn}</h1>
                                </div>
                            ))}
                        </div>
                    }
                </div>

                {modal_loja ?
                    <div className='home_modal' onClick={() => setmodal_loja(false)}>
                        <div className='home_modal_regiao' onClick={(e) => e.stopPropagation()}>
                            <IoMdClose className="iconex" onClick={() => setmodal_loja(false)} />

                            <h2>Baixe o app e crie sua loja de forma rápida e fácil</h2>
                            <img src={cel} />

                        </div>
                    </div>
                    : null}

                <CarrinhoFlutuante />
                <Footer />
            </div>
    );
}
