import React, { useState, useEffect } from 'react';
import fundo1 from '../img/fundo1.png'
import Server from '../Servidor';
import Header from './Header';
import Footer from '../Footer';
import fundo4 from '../img/fundo44.png';
import { CiImageOn } from "react-icons/ci";
import cel from '../img/celwhite.png'
import { IoMdClose } from "react-icons/io";
import { useLocation } from 'react-router-dom';
import CardProduto from '../Modelo1/CardProduto';
import CarrinhoFlutuante from './CarrinhoFlutuante';
import { useNavigate } from 'react-router-dom';
import { useDados } from "../context/Dados";
import { FiMenu, FiSearch } from "react-icons/fi";

export default function Anuncios() {
    const navigate = useNavigate();
    //puxa regiao do storedata, se nao tiver usa piracicaba

    var { dadosmain } = useDados()
    if (dadosmain.length == 0) {
        dadosmain.push([])
    }

    const [load0, setload0] = useState(true)

    const regiaoStore0 = localStorage.getItem("regiao");
    const regiaoStore = JSON.parse(regiaoStore0);
    const regiaoPadrao = 'Piracicaba, SP'

    const AllCategoriasList = ['Alimentos', 'Produtos, Coprodutos, Subprodutos', 'Sementes e Mudas', 'Variedades']

    const [load, setload] = useState(true)
    const [miniload, setminiload] = useState(false)
    const [res, setres] = useState([])
    const [produtos_anuncios, setprodutos_anuncios] = useState([])

    const [mininaopossui, setmininaopossui] = useState(false)

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const categoriaParams = searchParams.get('categoria');

    const [regiao, setregiao] = useState(regiaoStore ? regiaoStore.regiao : regiaoPadrao)
    const [categoria, setcategoria] = useState(categoriaParams);

    async function pegarProdutos(cat, reg) {
        try {
            const resposta = await Server({ codigo: 'pegar_produtos_anuncios_site2', regiao: reg, categoria: cat });

            if (resposta.error) {
                console.log(resposta.error)
            } else {
                if (resposta.produtos.length == 0 && resposta.anuncios.length == 0) {
                    setmininaopossui(true)
                } else {
                    setmininaopossui(false)
                }
                setres(resposta)
                setprodutos_anuncios([...resposta.produtos, ...resposta.anuncios])
                setload(false)
            }

        } catch (error) {
            console.log(error)
        }
    }

    const storedDataString = localStorage.getItem("dados");
    const storedData = JSON.parse(storedDataString);

    useEffect(() => {
        async function Inicio() {
            const currentTime = new Date().getTime();
            const last = storedData ? storedData.last : 0;
            const timeDifference = currentTime - last;

            if (timeDifference >= 24 * 60 * 60 * 1000) {
                //bota um timer aqui
                //so faz o login se passar 24h
                //se entrar aqui faz login e atualiza o store
                if (storedData) {
                    const resposta2 = await Server({ codigo: 'login', email: storedData.login, senha: storedData.senha })

                    if (resposta2.error) {
                        localStorage.removeItem("dados");
                    } else {
                        const all = resposta2.userinfo
                        const dadosasync = {
                            login: storedData.login,
                            senha: storedData.senha,
                            last: currentTime,
                            ...all
                        }
                        const allString = JSON.stringify(dadosasync);
                        localStorage.setItem("dados", allString);
                        dadosmain[0].dados = all
                        setload0(false)
                        pegarProdutos(categoria, regiao)
                    }
                } else {
                    dadosmain[0].dados = storedData
                    setload0(false)
                    pegarProdutos(categoria, regiao)
                }
            } else {
                dadosmain[0].dados = storedData
                setload0(false)
                pegarProdutos(categoria, regiao)
            }
        }
        Inicio()
    }, []);

    async function Next() {
        setminiload(true)

        try {
            const resposta = await Server({ codigo: 'pegar_produtos_anuncios_local', cidade: regiao, categoria, nextp: res.produtos_lastIds, nexta: res.anuncios_lastIds });

            if (resposta.error) {
                console.log(resposta.error)
            } else {
                if (resposta.produtos.length == 0 && resposta.anuncios.length == 0) {
                    setmininaopossui(true)
                } else {
                    setmininaopossui(false)
                }
                setres(resposta)
                produtos_anuncios.push(...resposta.produtos, ...resposta.anuncios)
                setminiload(false)
            }

        } catch (error) {
            console.log(error)
        }
    }

    async function AlterarCategoria(novaCategoria) {
        setcategoria(novaCategoria)
        setload(true)
        setres([])
        setprodutos_anuncios([])
        setmininaopossui(false)
        pegarProdutos(novaCategoria, regiao)
    }

    const alterarRegiao = (regiao) => {
        setload(true)
        setres([])
        setprodutos_anuncios([])
        setmininaopossui(false)

        const dadosasync = {
            regiao: regiao
        }
        const allString = JSON.stringify(dadosasync);
        localStorage.setItem("regiao", allString);

        setregiao(regiao)
        pegarProdutos(categoria, regiao)
    };

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        load0 ?
            <div style={{ height: '100vh', width: '100vw', background: '#fff', alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                <span className="load_inicial" />
            </div>
            :
            <div>
                <Header
                    mostrar_regiao={true}
                    regiao={regiao}
                    pegarProdutos={alterarRegiao}
                    nome={storedData ? storedData.nome : null}
                />

                <div className='home_anuncios_categorias_responsivo'>
                    <FiMenu onClick={toggleMenu} className="menu_icone" />
                </div>

                <div className='home_anuncios'>

                    <div className='home_anuncios_categorias_normal'>
                        <div className='home_anuncios_categorias' >
                            <h3>Categorias</h3>

                            {AllCategoriasList.map((cat) => (
                                <span key={cat} onClick={() => AlterarCategoria(cat)}
                                    className={cat === categoria ? 'home_anuncios_categoria_selecionada' : ''}
                                >
                                    {cat}
                                </span>
                            ))}
                        </div>
                    </div>

                    {/* Modal lateral */}
                    <div
                        style={{
                            position: 'fixed',
                            top: 0,
                            left: isMenuOpen ? '0' : '-100%',
                            width: '250px',
                            height: '100%',
                            backgroundColor: '#ffffff',
                            boxShadow: '-2px 0 5px rgba(0, 0, 0, 0.2)',
                            transition: 'left 0.3s ease',
                            zIndex: 1000,
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                padding: '10px',
                                backgroundColor: '#f5f5f5',
                                borderBottom: '1px solid #ddd',
                            }}
                        >
                            <button
                                onClick={toggleMenu}
                                style={{
                                    background: 'none',
                                    border: 'none',
                                    fontSize: '24px',
                                    cursor: 'pointer',
                                }}
                            >
                                ✕
                            </button>
                        </div>

                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                padding: '20px',
                            }}
                        >
                            <div className='home_anuncios_categorias' >

                                <h3>Categorias</h3>

                                {AllCategoriasList.map((cat) => (
                                    <span key={cat} onClick={() => { toggleMenu(); AlterarCategoria(cat) }}
                                        className={cat === categoria ? 'home_anuncios_categoria_selecionada' : ''}
                                    >
                                        {cat}
                                    </span>
                                ))}
                            </div>
                        </div>
                    </div>

                    {/* Overlay */}
                    {isMenuOpen && (
                        <div
                            onClick={toggleMenu}
                            style={{
                                position: 'fixed',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                zIndex: 999,
                            }}
                        ></div>
                    )}

                    {/* Overlay para fechar o menu ao clicar fora */}
                    {isMenuOpen && <div className="menu-overlay" onClick={toggleMenu}></div>}

                    <div className='home_anuncios_produtos'>
                        {load ?
                            <span className="load_inicial" style={{ alignSelf: 'flex-start' }} />
                            :
                            <div>
                                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center' }}>
                                    {produtos_anuncios.map((item, index) => (
                                        <div style={{ flex: '0 0 auto' }}>
                                            <CardProduto key={index} item={item} detalhes={() => navigate(`/detalhes/${item.categoria}/${item.id.slice(11)}/${item.userforn || item.usuario}`, { state: { item } })} />
                                        </div>
                                    ))}
                                </div>

                                {miniload ?
                                    <div style={{ display: 'flex', padding: 40, justifyContent: 'center' }}>
                                        <span className="load_inicial" />
                                    </div>
                                    :
                                    mininaopossui ? null :
                                        <div className='home_vermaisprodutos'>
                                            <button onClick={() => Next()}>Carregar mais</button>
                                        </div>
                                }
                            </div>
                        }
                    </div>
                </div>

                <CarrinhoFlutuante />

                <Footer />
            </div>
    );
}
