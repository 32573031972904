import React, { useState } from 'react';
import { IoMdClose } from "react-icons/io";
import { FaMapMarkedAlt } from "react-icons/fa";
import cidades from '../Locais/Cidades';

export default function ModalRegiao({ regiao, mudarRegiao, fechar }) {

    const [cidadeinput, setcidadeinput] = useState(regiao)
    const [drop, setdrop] = useState(false)

    function HandleInput({ text }) {
        setcidadeinput(text.target.value)
        if (!drop) {
            setdrop(true)
        }
    }

    return (
        <div>

            <div className='home_modal' onClick={() => { fechar(); setdrop(false); setcidadeinput(regiao) }}>
                <div className='home_modal_regiao' onClick={(e) => e.stopPropagation()}>
                    <IoMdClose className="iconex" onClick={() => { fechar(); setdrop(false); setcidadeinput(regiao) }} />

                    <FaMapMarkedAlt className='home_icone_regiao' />

                    <div className="home_modal_regiao_div">

                        <span className="modal_conta_titulos" style={{ marginBottom: 10 }}>Selecione uma cidade</span>
                        <input value={cidadeinput} onChange={(text) => HandleInput({ text })} className="modal_conta_inputs" placeholder="Cidade"></input>

                        {drop ?
                            <div className="dropdown_cidades">
                                <div className="dropdown_scroll">
                                    {cidades
                                        .filter(cidade => cidade.toLowerCase().includes(cidadeinput.toLowerCase()))
                                        .map((cidade, index) => (
                                            <span onClick={() => { setcidadeinput(cidade); setdrop(false) }} key={index} className="dropdown_texto">{cidade}</span>
                                        ))}
                                </div>
                            </div>
                            : null}

                        {regiao && !drop ?
                            <span onClick={() => mudarRegiao(cidadeinput)} className="btncontinuar">Continuar</span>
                            : null}
                    </div>
                </div>
            </div>

        </div>
    );
}
