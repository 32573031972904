import React, { useState, useEffect } from 'react';
import logowhite from '../img/logowhite.png'
import { FiMapPin } from "react-icons/fi";
import { IoIosArrowDown } from "react-icons/io";
import { FiUser } from "react-icons/fi";
import ModalRegiao from './ModalRegiao';
import cel from '../img/celwhite.png'
import { IoMdClose } from "react-icons/io";
import EntrarConta from '../Modelo1/EntrarConta';
import CriarConta from '../Modelo1/CriarConta';
import { useDados } from "../context/Dados";
import Perfil from '../Modelo1/Perfil';
import Server from '../Servidor';
import { FiMenu, FiSearch } from "react-icons/fi";

export default function Header({ mostrar_regiao, pegarProdutos, nome }) {

    var { dadosmain } = useDados()
    if (dadosmain.length == 0) {
        dadosmain.push([])
    }

    const dados = dadosmain[0].dados

    //reseta ao mudar de página, tem que pegar o login do async

    // useEffect(() => {
    //     async function Inicio() {
    //         const storedDataString = localStorage.getItem("dados");
    //         if (storedDataString) {
    //             const storedData = JSON.parse(storedDataString);
    //             const resposta2 = await Server({ codigo: 'login', email: storedData.login, senha: storedData.senha })

    //             if (resposta2.error) {
    //                 localStorage.removeItem("dados");
    //             } else {
    //                 const all = resposta2.userinfo
    //                 dadosmain[0].dados = all
    //             }
    //         }
    //     }
    //     Inicio()
    // }, []);

    const regiaoStore0 = localStorage.getItem("regiao");
    const regiaoStore = JSON.parse(regiaoStore0);
    const regiaoPadrao = 'Piracicaba, SP'

    const regiao = regiaoStore ? regiaoStore.regiao : regiaoPadrao

    const [modal_regiao, setmodal_regiao] = useState(false)
    const [modal_anuncie, setmodal_anuncie] = useState(false)
    const [display_entrar_criar, setdisplay_entrar_criar] = useState(false)
    const [display_entrar, setdisplay_entrar] = useState(false)
    const [display_criar, setdisplay_criar] = useState(false)
    const [display_perfil, setdisplay_perfil] = useState(false)

    const mudarRegiao = (novaRegiao) => {
        setmodal_regiao(false)
        pegarProdutos(novaRegiao)
    };

    const fecharModalRegiao = () => {
        setmodal_regiao(false)
    };

    function VoltarCriarConta() {
        setdisplay_criar(false)
        setdisplay_entrar_criar(true)
    }

    function VoltarEntrarConta() {
        setdisplay_entrar(false)
        setdisplay_entrar_criar(true)
    }

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };


    return (
        <div>
            <div className='home_header_div'>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: 20 }}>
                    <a href='/' style={{ textDecoration: 'none', color: 'inherit' }}>
                        <img src={logowhite} />
                    </a>

                    {mostrar_regiao ?
                        <div className='home_header_regiao' onClick={() => setmodal_regiao(true)}>
                            <FiMapPin style={{ fontSize: 25, color: '#fff' }} />

                            <div style={{ marginLeft: 10, display: 'flex', flexDirection: 'column' }}>
                                <a style={{ color: '#fff' }}>Região</a>

                                <div style={{ flexDirection: 'row', marginTop: 5, justifyContent: 'center', display: 'flex' }}>

                                    <a style={{ color: '#fff' }}>{regiao}</a>
                                    <IoIosArrowDown style={{ fontSize: 20, color: '#fff', marginLeft: 5 }} />
                                </div>
                            </div>
                        </div>
                        : null}
                </div>

                <div>
                    <a className='home_header_btn' href={`/anuncios?categoria=Alimentos`} style={{ textDecoration: 'none' }}>Anúncios</a>

                    <a className='home_header_btn' href='/lojas' style={{ textDecoration: 'none' }}>Lojas</a>

                    <a className='home_header_btn' href='/blockchain' style={{ textDecoration: 'none' }}>Blockchain</a>

                    <button className='home_header_btn' onClick={() => setmodal_anuncie(true)}>
                        <a>Anuncie grátis</a>
                    </button>
                </div>


                <div className='home_header_conta' onClick={() => nome ? setdisplay_perfil(true) : setdisplay_entrar_criar(true)}>
                    <FiUser style={{ fontSize: 28, marginLeft: 20, color: '#fff' }}></FiUser>
                    <div style={{ marginLeft: 10 }}>

                        {nome ?
                            <a style={{ color: '#fff' }}>{nome}</a>
                            :
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <a style={{ color: '#fff' }}>Cadastre-se ou</a>
                                <a style={{ color: '#fff' }}>Faça login</a>
                            </div>
                        }
                    </div>
                </div>

            </div>

            <div className='home_header_div_responsivo'>

                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                    <FiMenu onClick={toggleMenu} style={{ fontSize: 25, color: '#fff', padding: 10, borderRadius: 15, marginLeft: 20, marginTop: 0, cursor: 'pointer' }} />

                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <a href='/' style={{ textDecoration: 'none', color: 'inherit' }}>
                            <img style={{ height: 50, marginTop: 20, marginBottom: 15 }} src={logowhite} />
                        </a>
                    </div>

                    <div style={{ cursor: 'pointer', marginRight: 20, marginTop: 0 }} onClick={() => nome ? setdisplay_perfil(true) : setdisplay_entrar_criar(true)}>
                        <FiUser style={{ fontSize: 28, color: '#fff' }}></FiUser>
                    </div>
                </div>

                {mostrar_regiao ?
                    <div className='home_header_regiao' onClick={() => setmodal_regiao(true)}>
                        <FiMapPin style={{ fontSize: 25, color: '#fff', marginLeft: 25 }} />

                        <div style={{ marginLeft: 10, display: 'flex', flexDirection: 'column' }}>
                            <div style={{ flexDirection: 'row', marginTop: 5, justifyContent: 'center', display: 'flex' }}>

                                <a style={{ color: '#fff' }}>{regiao}</a>
                                <IoIosArrowDown style={{ fontSize: 20, color: '#fff', marginLeft: 5 }} />
                            </div>
                        </div>
                    </div>
                    : null}
            </div>

            {/* Modal lateral */}
            <div
                style={{
                    position: 'fixed',
                    top: 0,
                    left: isMenuOpen ? '0' : '-100%',
                    width: '250px',
                    height: '100%',
                    backgroundColor: '#ffffff',
                    boxShadow: '-2px 0 5px rgba(0, 0, 0, 0.2)',
                    transition: 'left 0.3s ease',
                    zIndex: 1000,
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        padding: '10px',
                        backgroundColor: '#f5f5f5',
                        borderBottom: '1px solid #ddd',
                    }}
                >
                    <button
                        onClick={toggleMenu}
                        style={{
                            background: 'none',
                            border: 'none',
                            fontSize: '24px',
                            cursor: 'pointer',
                        }}
                    >
                        ✕
                    </button>
                </div>

                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        padding: '20px',
                    }}
                >
                    <a href={`/anuncios?categoria=Alimentos`} className='home_itens_menu_mobile'>Anúncios</a>

                    <a href="/lojas" className='home_itens_menu_mobile'>Lojas</a>

                    <a href="/blockchain" className='home_itens_menu_mobile'>Blockchain</a>

                    <button
                        onClick={() => {
                            toggleMenu();
                            setmodal_anuncie(true)
                        }}
                        style={{
                            margin: '15px 0',
                            padding: '10px',
                            background: '#55a630',
                            color: '#fff',
                            border: 'none',
                            borderRadius: '5px',
                            cursor: 'pointer',
                            fontWeight: 900,
                            fontSize: '18px',
                            transition: 'background 0.3s',
                        }}>Anuncie grátis
                    </button>
                </div>
            </div>

            {/* Overlay */}
            {isMenuOpen && (
                <div
                    onClick={toggleMenu}
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        zIndex: 999,
                    }}
                ></div>
            )}

            {/* Overlay para fechar o menu ao clicar fora */}
            {isMenuOpen && <div className="menu-overlay" onClick={toggleMenu}></div>}

            {modal_regiao ?
                <ModalRegiao
                    regiao={regiao}
                    mudarRegiao={mudarRegiao}
                    fechar={fecharModalRegiao}
                />
                : null}

            {modal_anuncie ?
                <div className='home_modal' onClick={() => setmodal_anuncie(false)}>
                    <div className='home_modal_regiao' onClick={(e) => e.stopPropagation()}>
                        <IoMdClose className="iconex" onClick={() => setmodal_anuncie(false)} />

                        <h2>Baixe o app e anuncie gratuitamente</h2>
                        <img src={cel} />

                    </div>
                </div>
                : null}

            {display_entrar_criar ?
                <div className="home_modal" onClick={() => setdisplay_entrar_criar(false)}>
                    <div className='home_modal_regiao' onClick={(e) => e.stopPropagation()} style={{ animation: 'none' }}>
                        <IoMdClose className="iconex" onClick={() => setdisplay_entrar_criar(false)} />
                        <FiUser style={{ color: '#fff', fontSize: 60, marginBottom: 10 }} />

                        <span onClick={() => { setdisplay_entrar_criar(false); setdisplay_entrar(true) }} className="modal_conta_entrar_criar">Entrar</span>
                        <span onClick={() => { setdisplay_entrar_criar(false); setdisplay_criar(true) }} className="modal_conta_entrar_criar">Criar conta</span>
                    </div>
                </div>
                : null}

            {display_entrar ?
                <EntrarConta
                    voltar={() => VoltarEntrarConta()}
                    fechar={() => setdisplay_entrar(false)}
                />
                : null}

            {display_criar ?
                <CriarConta
                    voltar={() => VoltarCriarConta()}
                    fechar={() => setdisplay_criar(false)}
                />
                : null}

            {display_perfil ?
                <Perfil
                    fechar={() => setdisplay_perfil(false)}
                />
                : null}

        </div>
    );
}
