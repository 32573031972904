import React, { useState, useEffect } from 'react';
import fundo from './img/fundo.png'
import fundo1 from './img/fundo1.png'
import fundo2 from './img/fundo2.png'
import fundo3 from './img/fundo3.png'
import playstore from './img/googleplay.png'
import appstore from './img/appstore.svg'
import logo from './img/logo.png'
import Footer from './Footer';
import { IoIosArrowDroprightCircle } from "react-icons/io";
import { IoIosArrowDropleftCircle } from "react-icons/io";
import { FaInstagram } from "react-icons/fa";
import { HiOutlineMail } from "react-icons/hi";
import Server from './Servidor';
import CardProduto from './Modelo1/CardProduto';
import { FaMapMarkedAlt } from "react-icons/fa";
import Header from './Home/Header';
import ModalRegiao from './Home/ModalRegiao';
import { useNavigate } from 'react-router-dom';
import { useDados } from "./context/Dados";
import CarrinhoFlutuante from './Home/CarrinhoFlutuante';

export default function App() {
  const navigate = useNavigate();

  var { dadosmain } = useDados()
  if (dadosmain.length == 0) {
    dadosmain.push([])
  }

  const [load0, setload0] = useState(true)

  const divfundo1 = {
    height: '100%', width: '100%', flexDirection: 'column', backgroundImage: `url(${fundo1})`, backgroundSize: 'cover',
    display: 'flex', justifyContent: 'center'
  }

  const divfundo2 = {
    height: '100%', width: '100%', flexDirection: 'column', backgroundImage: `url(${fundo2})`, backgroundSize: 'cover',
    display: 'flex', justifyContent: 'center'
  }

  const divfundo3 = {
    height: '100%', width: '100%', flexDirection: 'column', backgroundImage: `url(${fundo3})`, backgroundSize: 'cover',
    display: 'flex', justifyContent: 'center'
  }

  const regiaoStore0 = localStorage.getItem("regiao");
  const regiaoStore = JSON.parse(regiaoStore0);
  const regiaoPadrao = 'Piracicaba, SP'

  const [paginaAtual, setpaginaAtual] = useState(0)
  const [regiao, setregiao] = useState(regiaoStore ? regiaoStore.regiao : regiaoPadrao)
  const [load, setload] = useState(true)
  const [res, setres] = useState([])
  const [produtos_anuncios, setprodutos_anuncios] = useState([])
  const [modalregiao, setmodal_regiao] = useState(false)

  // async function Inicio(valor) {
  //   try {
  //     const resposta = await Server({ codigo: 'pegar_produtos_anuncios_site', regiao: valor });

  //     if (resposta.error) {
  //       console.log(resposta.error)
  //     } else {
  //       const storedDataString = localStorage.getItem("dados");
  //       if (storedDataString) {
  //         const storedData = JSON.parse(storedDataString);
  //         const resposta2 = await Server({ codigo: 'login', email: storedData.login, senha: storedData.senha })

  //         if (resposta2.error) {
  //           localStorage.removeItem("dados");
  //         } else {
  //           const all = resposta2.userinfo
  //           dadosmain[0].dados = all
  //           setres(resposta)
  //           setprodutos_anuncios([...resposta.produtos, ...resposta.anuncios])
  //           setload(false)
  //         }
  //       } else {
  //         setres(resposta)
  //         setprodutos_anuncios([...resposta.produtos, ...resposta.anuncios])
  //         setload(false)
  //       }
  //     }

  //   } catch (error) {
  //     console.log(error)
  //   }
  // }

  async function Inicio2(valor) {
    try {
      const resposta = await Server({ codigo: 'pegar_produtos_anuncios_site', regiao: valor });

      if (resposta.error) {
        console.log(resposta.error)
      } else {
        setres(resposta)
        setprodutos_anuncios([...resposta.produtos, ...resposta.anuncios])
        setload(false)
      }

    } catch (error) {
      console.log(error)
    }
  }

  const storedDataString = localStorage.getItem("dados");
  const storedData = JSON.parse(storedDataString);

  useEffect(() => {
    async function Inicio() {
      const currentTime = new Date().getTime();
      const last = storedData ? storedData.last : 0;
      const timeDifference = currentTime - last;

      if (timeDifference >= 24 * 60 * 60 * 1000) {
        //bota um timer aqui
        //so faz o login se passar 24h
        //se entrar aqui faz login e atualiza o store
        if (storedData) {
          const resposta2 = await Server({ codigo: 'login', email: storedData.login, senha: storedData.senha })

          if (resposta2.error) {
            localStorage.removeItem("dados");
            setload0(false)
            Inicio2(regiao)
          } else {
            const all = resposta2.userinfo
            const dadosasync = {
              login: storedData.login,
              senha: storedData.senha,
              last: currentTime,
              ...all
            }
            const allString = JSON.stringify(dadosasync);
            localStorage.setItem("dados", allString);
            dadosmain[0].dados = all
            setload0(false)
            Inicio2(regiao)
          }
        } else {
          localStorage.removeItem("dados");
          setload0(false)
          Inicio2(regiao)
        }
      } else {
        dadosmain[0].dados = storedData
        setload0(false)
        Inicio2(regiao)
      }
    }
    Inicio()
  }, []);

  const paginas = [
    {
      id: 0,
      content:
        <div style={divfundo1}>
          <div className='home_div_titulo'>
            <h1>Marketplace <br /> do agronegócio</h1>

            <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
              <a target="_blank" href="https://play.google.com/store/apps/details?id=com.perseguidu.krl3">
                <img src={playstore} style={{ width: '119.66407px', height: '50px' }} />
              </a>
              {/* <a target="_blank" href="https://apps.apple.com/us/app/encurtagro/id6443944425?itsct=apps_box_link&itscg=30200">
                <img src={appstore} />
              </a> */}
            </div>
          </div>
        </div>
    },
    {
      id: 1,
      content:
        <div style={divfundo2}>
          <div className='home_div_titulo' style={{ marginBottom: 20 }}>
            <h1>Sobre</h1>
            <h2>A Encurtagro é uma startup de comercialização de produtos do agronegócio, que surgiu com o objetivo de encurtar a distância entre vendedor e comprador, facilitando todo o processo de compra e venda.</h2>
          </div>
        </div>
    },
    {
      id: 2,
      content:
        <div style={divfundo3}>
          <div className='home_div_contato'>
            <div style={{ marginBottom: 20, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <HiOutlineMail style={{ color: '#fff', fontSize: 30 }} />
              <span>contato@encurtagro.com</span>
            </div>

            <a target="_blank" href="https://www.instagram.com/encurtagro/" style={{ textDecoration: 'none', outline: 'none' }}>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <FaInstagram style={{ color: '#fff', fontSize: 30 }} />
                <span>encurtagro</span>
              </div>
            </a>

          </div>
        </div>
    },

  ];

  const mudarPagina = (novaPagina) => {
    // Verificar se deve ir para a última página ao retroceder da primeira
    if (novaPagina < 0) {
      setpaginaAtual(paginas.length - 1); // Vai para a última página
    }
    // Verificar se deve ir para a primeira página ao avançar da última
    else if (novaPagina >= paginas.length) {
      setpaginaAtual(0); // Vai para a primeira página
    }
    // Navegar normalmente dentro dos limites
    else {
      setpaginaAtual(novaPagina);
    }
  };

  const pegarProdutos = (regiao) => {
    setload(true)
    setmodal_regiao(false)

    const dadosasync = {
      regiao: regiao
    }
    const allString = JSON.stringify(dadosasync);
    localStorage.setItem("regiao", allString);

    setregiao(regiao)
    Inicio2(regiao)
  };

  const fecharModalRegiao = () => {
    setmodal_regiao(false)
  }

  function BtnVerMais(valor) {

    return (
      <div className='home_vermaisprodutos'>
        <button>
          <a href={`/anuncios?categoria=${valor.categoria}&regiao=${regiao}`} style={{ textDecoration: 'none', color: 'inherit' }}>Ver mais produtos</a>
        </button>
      </div>
    )
  }

  return (
    load0 ?
      <div style={{ height: '100vh', width: '100vw', background: '#fff', alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
        <span className="load_inicial" />
      </div>
      :
      <div>

        <Header
          regiao={regiao}
          mostrar_regiao={true}
          pegarProdutos={pegarProdutos}
          nome={storedData ? storedData.nome : null}
        />

        <div className="home_swipe-container">
          <div
            className="home_swipe-page"
            style={{ transform: `translateX(-${paginaAtual * 33.33}%)` }}
          >
            {paginas.map((page) => (
              <div
                key={page.id}
                className="home_swipe-content"
              >
                {page.content}
              </div>
            ))}
          </div>
          {/* Controles de Navegação */}
          <IoIosArrowDropleftCircle className="home_arrow-left" onClick={() => mudarPagina(paginaAtual - 1)} />
          <IoIosArrowDroprightCircle className="home_arrow-right" onClick={() => mudarPagina(paginaAtual + 1)} />

          <div className="home_swipe-controls">

            <div className="home_indicators">
              {paginas.map((_, index) => (
                <div
                  className={`home_indicator ${paginaAtual === index ? 'active' : ''}`}
                  onClick={() => mudarPagina(index)}
                />
              ))}
            </div>

          </div>
        </div>

        <div className='home_produtos'>
          {load ?
            <span className="load_inicial" />
            :
            res.categorias.length == 0 ?
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <FaMapMarkedAlt className='home_icone_regiao' />
                <span style={{ fontSize: 19, marginTop: 10 }}>Não há produtos nessa região: {regiao}</span>

                <div className='home_vermaisprodutos'>
                  <button onClick={() => setmodal_regiao(true)}>Mudar região</button>
                </div>
              </div>
              :
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {res.categorias.map((categoria) => (
                  <div>
                    <h3 style={{ marginTop: 20 }}>{categoria.id}</h3>

                    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center' }}>
                      {produtos_anuncios.map((item, index) => (
                        item.categoria == categoria.id ?
                          <div style={{ flex: '0 0 auto' }}>
                            <CardProduto key={index} item={item} detalhes={() => navigate(`/detalhes/${item.categoria}/${item.id.slice(11)}/${item.userforn || item.usuario}`, { state: { item } })} />
                          </div>
                          : null
                      ))}
                    </div>

                    <BtnVerMais categoria={categoria.id} />

                  </div>
                ))}
              </div>
          }
        </div>

        <CarrinhoFlutuante />

        <Footer />

        {modalregiao ?
          <ModalRegiao
            regiao={regiao}
            mudarRegiao={pegarProdutos}
            fechar={fecharModalRegiao}
          />
          : null}

      </div>
  );
}
